<template>
  <v-card id="account-setting-card">
    <div id="Gmap" style="padding: 30px" v-if="longitude != ''">
      <h1>Map</h1>
      <v-btn
        class="ma-2"
        :href="'https://www.google.com/maps/@' + latitude + ',' + longitude + ',17z'"
        target="_blank"
        outlined
        color="teal"
        @click="tabeRe()"
      >
        <v-icon>mdi-google-maps</v-icon>Get current location
        <!-- 
        <l-map style="height: 350px" :zoom="zoom" :center="center">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-circle-marker :lat-lng="circle.center" :radius="circle.radius" :color="circle.color" />
        </l-map> -->
      </v-btn>
      <!--    <l-map style="height: 350px" :zoom="zoom" :center="center">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-circle-marker :lat-lng="circle.center" :radius="circle.radius" :color="circle.color" />
      </l-map> -->
      <l-map style="height: 350px" :zoom="zoom" :center="center">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-circle-marker :lat-lng="circle.center" :radius="circle.radius" :color="circle.color" />
      </l-map>
    </div>
  </v-card>
</template>
<style scoped>
</style>
<script>
import { doc, getDoc } from 'firebase/firestore'
import { dbRef, locationClick } from '../../../../firebaseConfig'
import { getUID, getCurrentDeviceSelected } from '../../../../localStorage'
import { LMap, LTileLayer, LCircleMarker } from 'vue2-leaflet'
export default {
  components: {
    LMap,
    LTileLayer,
    LCircleMarker,
  },
  name: 'Gmap',
  data() {
    return {
      search: '',
      myitems: [],
      latitude: '',
      longitude: '',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 18,
      center: [47.31322, -1.319482],
      circle: {
        center: [47.41322, -1.0482],
        radius: 6,
        color: 'red',
      },
    }
  },
  mounted: function () {
    //this.myD();
    locationClick()
    this.getCurrentLocation()
  },
  methods: {
    async getCurrentLocation() {
      this.$store.commit('LOADER', true);
      let uid = getUID()
      const myDoc = doc(dbRef, 'location', uid, getCurrentDeviceSelected(), uid + '-' + getCurrentDeviceSelected())
      getDoc(myDoc)
        .then(res => {
          let latlng = res.data()
          this.latitude = latlng.latitude
          this.longitude = latlng.longitude

          this.center[0] = this.latitude
          this.center[1] = this.longitude

          this.circle.center[0] = this.latitude
          this.circle.center[1] = this.longitude

          console.log(this.center)
          console.log(this.circle)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })
    },
  },
}
</script>